<template>
<div class="device-unbind-index" id="page">
  <el-row class="sor-form-line row-start-between">
    <el-form :model="form" class="row-center-start" ref="formRef">
      <el-form-item>
        <el-input v-model="form.keyword" placeholder="姓名/IOT" clearable>
          <template #prepend>
            <i class="iconfont icon-sousuo" />
          </template>
        </el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="getList()">搜索</el-button>
      </el-form-item>
    </el-form>
  </el-row>
  <el-table :data="table.data" v-loading="table.loading">
    <el-table-column prop="txt_device_iot" label="IOT" align="center" />
    <el-table-column prop="txt_type_name" label="设备类型" align="center" />
    <el-table-column prop="dt_province_partner_agent" label="省代绑定时间" align="center" />
    <el-table-column prop="txt_province_partner_name" label="省代" align="center" />
    <el-table-column prop="dt_city_partner_agent" label="市代绑定时间" align="center" />
    <el-table-column prop="txt_city_partner_name" label="市代" align="center" />
    <el-table-column prop="dt_salesman_agent" label="业务员绑定时间" align="center" />
    <el-table-column prop="txt_salesman_name" label="业务员" align="center" />
    <el-table-column prop="dt_clinic_agent" label="诊所绑定时间" align="center" />
    <el-table-column prop="txt_clinic_agent_name" label="诊所医生" align="center" />
    <el-table-column prop="txt_clinic_name" label="诊所" align="center" />
    <el-table-column label="操作" align="center" class-name="table-option">
      <template #default="scope">
        <div class="table-icon" @click="goUnbind(scope.row)" :class="scope.row.is_activate!=1?'disabled':''">
          <el-icon>
            <CircleClose />
          </el-icon> 解绑
        </div>
      </template>
    </el-table-column>
  </el-table>
</div>
</template>

<script>
// 设备管理 - 设备解绑
import { reactive, toRefs } from 'vue';
import { CircleClose } from "@element-plus/icons-vue";
import { ElMessage, ElMessageBox } from 'element-plus';
import { deviceGetUnbind, deviceGoUnbind } from "api/apis.js";
export default {
  components: {
    CircleClose,
  },
  setup() {
    const state = reactive({
      form: {
        keyword: ""
      },
      table: {
        data: [],
        loading: false
      }
    });

    // 获取列表数据
    const getList = () => {
      if ((state.form.keyword||"").length == 0) {
        state.table.data =[];
      } else {
        state.table.loading = true;
        deviceGetUnbind({
          search_key: state.form.keyword
        }).then(response => {
          if (response.code == 200) {
            state.table.data = response.data;
          } else {
            ElMessage.error(response.msg);
          }
        }).catch(e => {
          console.error(e);
        }).finally(() => {
          state.table.loading = false;
        })
      }
    };

    // 解绑
    const goUnbind = item => {
      ElMessageBox.prompt("解绑原因", "设备解绑", {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        inputValidator: value => {
          if ((value || "").length < 10) {
            return "请输入至少十个字符";
          } else {
            return true;
          }
        },
        inputPlaceholder: "请输入钉钉审批号"
      }).then((value) => {
        ElMessageBox.confirm(
          `确定要解绑设备【${item.txt_device_iot}】吗？此操作不可逆`,
          '提示', {
            distinguishCancelAndClose: true,
            confirmButtonText: '确定',
            cancelButtonText: '取消',
          }
        ).then(() => {
          deviceGoUnbind({
            txt_device_iot: item.txt_device_iot,
            txt_comment: value.value
          }).then(response => {
            if (response.code == 200) {
              ElMessage.success("解绑成功");
              getList();
            } else {
              ElMessage.error("解绑失败" + response.msg);
            }
          })
        })
      }).catch(() => {})
    };

    return {
      ...toRefs(state),
      getList,
      goUnbind
    }
  }
}
</script>

<style lang="scss" scoped>
.device-unbind-index {
  .el-table {
    height: calc(100% - 60px);
  }
}
</style>
